import React, { useState, useEffect } from "react";
import { makeStyles, StylesProvider } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TextField from "@material-ui/core/TextField";
import RedirType from "./RedirType";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SelectInput from "@material-ui/core/Select/SelectInput";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 100
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  content: {
    flexGrow: 1,
    marginLeft: "275px",
    width: "77%",
    marginTop: "20px",
    borderRadius: 10
  },
  titletyp: {
    marginLeft: "275px",
    marginTop: "80px",
    fontSize: "1rem",
    fontWeight: "1000"
  },
  chipstyle: {
    marginLeft: "280px",
    fontWeight: "800",
    margin: theme.spacing(0.5),
    marginTop: "18px"
  },
  savestyle: {
    fontWeight: "800",
    margin: theme.spacing(0.5),
    marginTop: "18px"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch"
  },
  divsstyle: {
    marginLeft: "250px",
    marginTop: "19px"
  },
  texttyp: {
    marginLeft: "275px",
    marginTop: "18px",
    fontSize: ".85rem",
    fontWeight: "0"
  }
}));

export default function NewRedir() {
  const classes = useStyles();
  const [inputList, setInputList] = useState([]);
  const [redirects, setRedirects] = useState({
    rdate: "",
    rdomain: "",
    roldurl: "",
    rnewurl: "",
    rnotes: "",
    rdelete: false
  });
  const [chkstate, chksetState] = useState(false);
  const [saveopen, setSaveOpen] = useState(false);
  const [saveopenerr, setSaveOpenerr] = useState(false);
  const [duplicateOpenerr, setDuplicateOpenerr] = useState(false);
  const [recordsnew, setRecordsnew] = useState([]);
  const [errorxd, setErrorxd] = useState(false);
  const [errorxdom, setErrorxdom] = useState(false);
  const [errorxold, setErrorxold] = useState(false);
  const [errorxnew, setErrorxnew] = useState(false);
  const [duplicateFlag, setDuplicateFlag] = useState(false);
  const addnewurl =
    "https://75fpbsu5tj.execute-api.eu-west-2.amazonaws.com/default/redirectssinglecommit";
  var pubTestVar = `{"TableName":"redirects_intermediate","Item":{"id_intermediate":{"N":"`;

  var recdate_input = "";
  var duplicateCheck = ``;
  var duplicateCheck = '{"TableName": "redirects_intermediate","ExpressionAttributeNames": {"#nhost": "host","#npath": "path","#ninterim": "interim"},"ExpressionAttributeValues": {":vhost":"';
  const duplicatecheckURL = 'https://r5cafhprd8.execute-api.eu-west-2.amazonaws.com/default/redirectsduplicatecheck';
  var countx = 0;

  useEffect(() => {
    //const jsonObj = JSON.parse(recordsnew);
    // const jsonObj = JSON.parse(recordsnew);
    // axios
    //   .post(addnewurl, recordsnew, {
    //     headers: {
    //       "x-api-key": `XxuYdia6eAanT3nv2zgaV41ngBkRCCGr5lXXIAXW`
    //     }
    //   })
    //   .then(response => {
    //     if (response.status !== 200) {
    //       console.log("error", response);
    //     } else {
    //     }
    //   })
    //   .catch(error => {
    //     console.log("error here ", error);
    //   });
  }, [recordsnew]);

 

  const CardComponent = () => {
    return (
      <Card className={classes.content} variant="outlined" direction="row">
        <CardContent>
          <Typography
            className={classes.title}
            color="textPrimary"
            style={{ marginBottom: "25px" }}
          >
            Redirect Record {inputList.length + 2}
          </Typography>
          <TextField
            label="Date"
            id="rdate"
            name="rdate"
            placeholder="dd-mm-yy"
            type="date"
            //defaultValue ={currdatevalues.currDate}
            className={classes.textField}
            helperText="Enter a valid date"
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            onChange={cardChange}
            onBlur={handleBlurxd}
            error={errorxd}
            required
          />
          <TextField
            label="Domain"
            id="rdomain"
            name="rdomain"
            style={{ margin: 8 }}
            placeholder="www.exampledomain.co.uk"
            helperText="Enter a valid domain"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={cardChange}
            onBlur={handleBlurxdom}
            error={errorxdom}
          />
          <TextField
            id="roldurl"
            name="roldurl"
            label="Old URL"
            style={{ margin: 8 }}
            placeholder="/path/routing/page"
            helperText="Enter a old URL"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={cardChange}
            required
          />
          <TextField
            id="rnewurl"
            name="rnewurl"
            label="New URL"
            style={{ margin: 8 }}
            placeholder="https://www.exampledomain.co.uk/path/new/page"
            helperText="Enter a valid new URL"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={cardChange}
            required
          />
          <TextField
            id="rnotes"
            name="rnotes"
            label="Notes"
            style={{ margin: 8 }}
            placeholder="Lorem Ipsum"
            helperText="Notes on the redirect"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={cardChange}
          />
          <RedirType newdelchk={chkstate} checkChange={checkChange} />
        </CardContent>
      </Card>
    );
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function SaveMessage(props) {
    const responseMessage = props.responseMessage;
    return (
      <Snackbar
        open={saveopen}
        autoHideDuration={6000}
        onClose={() => {
          setSaveOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSaveOpen(false);
          }}
          severity="success"
        >
          {responseMessage}
        </Alert>
      </Snackbar>
    );
  }

  function SaveMessageErr(props) {
    const responseMessage = props.responseMessage;
    return (
      <Snackbar
        open={saveopenerr}
        autoHideDuration={6000}
        onClose={() => {
          setSaveOpenerr(false);
        }}
      >
        <Alert
          onClose={() => {
            setSaveOpenerr(false);
          }}
          severity="error"
        >
          {responseMessage}
        </Alert>
      </Snackbar>
    );
  }

  function DuplicateMessageErr(props) {
    const responseMessage = props.responseMessage;
    return (
      <Snackbar
        open={duplicateOpenerr}
        autoHideDuration={6000}
        onClose={() => {
          setDuplicateOpenerr(false);
        }}
      >
        <Alert
          onClose={() => {
            setDuplicateOpenerr(false);
          }}
          severity="error"
        >
          {responseMessage}
        </Alert>
      </Snackbar>
    );
  }

  const addNewCard = event => {
    //      const min = 9999;
    // const max = 999999999;
    // const rand = Math.round(min + Math.random() * (max - min));

    // setRecordsnew([
    //   ...recordsnew,
    //   {
    //     recnumber: rand,
    //     recdate: redirects.rdate,
    //     recdomain: redirects.rdomain,
    //     recoldurl: redirects.roldurl,
    //     recnewurl: redirects.rnewurl,
    //     recnotes: redirects.rnotes,
    //     recdelete: chkstate
    //   }
    // ]);
    // chksetState(false);
    // setInputList(inputList.concat(<CardComponent key={inputList.length} />));
    window.location.reload(false);
  };

  const addRecAction = () => {
    const min = 9999;
    const max = 999999999;
    const rand = Math.round(min + Math.random() * (max - min));
    // setRecordsnew([
    //   ...recordsnew,
    //   {
    //     recnumber: rand,
    //     recdate: redirects.rdate,
    //     recdomain: redirects.rdomain,
    //     recoldurl: redirects.roldurl,
    //     recnewurl: redirects.rnewurl,
    //     recnotes: redirects.rnotes,
    //     recdelete: chkstate
    //   }
    // ]);
    //console.log (rand);

  
      
      if (
        !errorxd &&
        !errorxdom &&
        !errorxnew &&
        !errorxold &&
        redirects.rdomain &&
        redirects.roldurl &&
        redirects.rnewurl
      )
      {
        duplicateCheck = duplicateCheck.concat(redirects.rdomain);
        duplicateCheck = duplicateCheck.concat('",":vpath":"');
        duplicateCheck = duplicateCheck.concat(redirects.roldurl);
        duplicateCheck = duplicateCheck.concat('",":vinterim": "true"},"FilterExpression": "#npath = :vpath AND #nhost = :vhost AND #ninterim = :vinterim"}'); 
        console.log(duplicateCheck);         
        axios
          .post(duplicatecheckURL, duplicateCheck, {
            headers: {
              "x-api-key": `hG3McFwoHsaPNItMY9MrE82p8Fyg95a49WgAMdbG`
            }
          })
          .then(response => {
            if (response.data.Count === 0) {
              setRecordsnew({
                recnumber: rand,
                recdate: redirects.rdate,
                recdomain: redirects.rdomain,
                recoldurl: redirects.roldurl,
                recnewurl: redirects.rnewurl,
                recnotes: redirects.rnotes,
                recdelete: chkstate
              });
        
              pubTestVar = pubTestVar.concat(rand);
              pubTestVar = pubTestVar.concat(`"},"host":{"S":"`);
              pubTestVar = pubTestVar.concat(redirects.rdomain);
              pubTestVar = pubTestVar.concat(`"},"path":{"S":"`);
              pubTestVar = pubTestVar.concat(redirects.roldurl);
              pubTestVar = pubTestVar.concat(`"},"redirectURL":{"S":"`);
              pubTestVar = pubTestVar.concat(redirects.rnewurl);
              pubTestVar = pubTestVar.concat(`"},"notes":{"S":"`);
              pubTestVar = pubTestVar.concat(redirects.rnotes);
              pubTestVar = pubTestVar.concat(`"},"isDelete":{"S":"`);
              pubTestVar = pubTestVar.concat(chkstate);
              pubTestVar = pubTestVar.concat(`"},"interim":{"S":"true`);
              pubTestVar = pubTestVar.concat(`"}}}`);
        
              if (
                !errorxd &&
                !errorxdom &&
                !errorxnew &&
                !errorxold &&
                redirects.rdomain &&
                redirects.roldurl &&
                redirects.rnewurl
              ) {
                console.log(pubTestVar);
                setSaveOpen(true);
                axios
                  .post(addnewurl, pubTestVar, {
                    headers: {
                      "x-api-key": `BFRzNUdNjTgNxeUCh9KlaNqWMg4DvewaNypLZHP9`
                    }
                  })
                  .then(response => {
                    if (response.status !== 200) {
                      console.log("error", response);
                    } else {
                    }
                  })
                  .catch(error => {
                    console.log("error here ", error);
                    setSaveOpenerr(true);
                  });
              } else {
                console.log("Error");
                setSaveOpenerr(true);
              }
              console.log(errorxd, errorxdom, errorxnew, errorxold);



            } else { 
              console.log("duplicate");
              setDuplicateOpenerr(true);
              console.log(response.data.Count);
              setDuplicateFlag(true);
              //setSaveOpenerr(true);
            }
          })
          .catch(error => {
            console.log("error here ", error);
          });

      }
      else
      {
        setSaveOpenerr(true);
      }
      

      
    // if (!duplicateFlag)
    // {
    //   setRecordsnew({
    //     recnumber: rand,
    //     recdate: redirects.rdate,
    //     recdomain: redirects.rdomain,
    //     recoldurl: redirects.roldurl,
    //     recnewurl: redirects.rnewurl,
    //     recnotes: redirects.rnotes,
    //     recdelete: chkstate
    //   });

    //   pubTestVar = pubTestVar.concat(rand);
    //   pubTestVar = pubTestVar.concat(`"},"host":{"S":"`);
    //   pubTestVar = pubTestVar.concat(redirects.rdomain);
    //   pubTestVar = pubTestVar.concat(`"},"path":{"S":"`);
    //   pubTestVar = pubTestVar.concat(redirects.roldurl);
    //   pubTestVar = pubTestVar.concat(`"},"redirectURL":{"S":"`);
    //   pubTestVar = pubTestVar.concat(redirects.rnewurl);
    //   pubTestVar = pubTestVar.concat(`"},"notes":{"S":"`);
    //   pubTestVar = pubTestVar.concat(redirects.rnotes);
    //   pubTestVar = pubTestVar.concat(`"},"isDelete":{"S":"`);
    //   pubTestVar = pubTestVar.concat(chkstate);
    //   pubTestVar = pubTestVar.concat(`"},"interim":{"S":"true`);
    //   pubTestVar = pubTestVar.concat(`"}}}`);

    //   if (
    //     !errorxd &&
    //     !errorxdom &&
    //     !errorxnew &&
    //     !errorxold &&
    //     redirects.rdomain &&
    //     redirects.roldurl &&
    //     redirects.rnewurl
    //   ) {
    //     console.log(pubTestVar);
    //     setSaveOpen(true);
    //     axios
    //       .post(addnewurl, pubTestVar, {
    //         headers: {
    //           "x-api-key": `BFRzNUdNjTgNxeUCh9KlaNqWMg4DvewaNypLZHP9`
    //         }
    //       })
    //       .then(response => {
    //         if (response.status !== 200) {
    //           console.log("error", response);
    //         } else {
    //         }
    //       })
    //       .catch(error => {
    //         console.log("error here ", error);
    //         setSaveOpenerr(true);
    //       });
    //   } else {
    //     console.log("Error");
    //     setSaveOpenerr(true);
    //   }
    //   console.log(errorxd, errorxdom, errorxnew, errorxold);
    // }
  };

  const cardChange = event => {
    recdate_input = "";
    const { name, value } = event.target;
    setRedirects(prevState => ({
      ...prevState,
      [name]: value
    }));

    // setRecordsnew( [...recordsnew, event.target.value]);

    if (event.target.name === "rdate") {
      recdate_input = event.target.value;
    }
  };

  const checkChange = event => {
    chksetState(event.target.checked);
  };

  const handleBlurxd = event => {
    setErrorxd(false);
    if (event.target.name === "rdate") {
      const currentDatex = new Date().toISOString().substring(0, 10);
      if (event.target.value < currentDatex) {
        setErrorxd(true);
      } else {
        setErrorxd(false);
      }
    }
  };

  const handleBlurxdom = event => {
    setErrorxdom(false);
    const test = event.target.value;

    var regdtest = test.search(
      /^(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))$/g
    );

    if (event.target.name === "rdomain") {
      // console.log(event.target.value);

      if (regdtest < 0) {
        setErrorxdom(true);
      } else {
        pubTestVar = pubTestVar.concat(event.target.value);
        pubTestVar = pubTestVar.concat(`"}, "path": {S: "`);
        setErrorxdom(false);
      }
    }
  };

  const handleBlurxold = event => {
    setErrorxold(false);
    const test = event.target.value;

    var regdtest = test.search(/^\/[/.a-zA-Z0-9-]+$/g);

    //console.log(regdtest);

    if (event.target.name === "roldurl") {
      //console.log(event.target.value);

      if (regdtest < 0) {
        setErrorxold(true);
      } else {
        setErrorxold(false);
      }
    }
  };

  const handleBlurxnew = event => {
    setErrorxnew(false);
    const test = event.target.value;

    var regdtest = test.search(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
    );

    if (event.target.name === "rnewurl") {
      //console.log(event.target.value);

      if (regdtest < 0) {
        setErrorxnew(true);
      } else {
        setErrorxnew(false);
      }
    }
  };

  const handleBlurnotes = event => {};

  return (
    <>
      <Typography gutterBottom className={classes.titletyp}>
        New Redirects
      </Typography>
      <Divider variant="middle" className={classes.divsstyle} />
      <Chip
        icon={<AddCircleIcon style={{ fill: "black" }} />}
        label="Add Record"
        onClick={addNewCard}
        className={classes.chipstyle}
        variant="outlined"
        style={{ borderRadius: 5 }}
      />
      <Chip
        icon={<CheckCircleIcon style={{ fill: "white" }} />}
        label="Save"
        onClick={addRecAction}
        className={classes.savestyle}
        color="primary"
        style={{ backgroundColor: "black", borderRadius: 5 }}
      />
      <Card className={classes.content} variant="outlined" direction="row">
        <CardContent>
          <Typography
            className={classes.title}
            color="textPrimary"
            style={{ marginBottom: "25px" }}
          >
            Redirect Record
          </Typography>
          <TextField
            label="Date"
            id="rdate"
            name="rdate"
            placeholder="dd-mm-yy"
            type="date"
            //defaultValue ={currdatevalues.currDate}
            className={classes.textField}
            helperText="Enter a valid date"
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            onChange={cardChange}
            onBlur={handleBlurxd}
            error={errorxd}
            required
          />
          <TextField
            label="Domain"
            id="rdomain"
            name="rdomain"
            style={{ margin: 8 }}
            placeholder="example.exampledomain.co.uk"
            helperText="Enter a valid domain"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={cardChange}
            onBlur={handleBlurxdom}
            error={errorxdom}
            required
          />
          <TextField
            id="roldurl"
            name="roldurl"
            label="Old URL"
            style={{ margin: 8 }}
            placeholder="/path/routing/page"
            helperText="Enter a old URL"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={cardChange}
            onBlur={handleBlurxold}
            error={errorxold}
            required
          />
          <TextField
            id="rnewurl"
            name="rnewurl"
            label="New URL"
            style={{ margin: 8 }}
            placeholder="https://www.exampledomain.co.uk/path/new/page"
            helperText="Enter a valid new URL"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={cardChange}
            onBlur={handleBlurxnew}
            error={errorxnew}
            required
          />
          <TextField
            id="rnotes"
            name="rnotes"
            label="Notes"
            style={{ margin: 8 }}
            placeholder="Lorem Ipsum"
            helperText="Notes on the redirect"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={cardChange}
            onBlur={handleBlurnotes}
          />
          <RedirType newdelchk={chkstate} checkChange={checkChange} />
        </CardContent>
      </Card>

      {inputList}

      <SaveMessage
        responseMessage="Redirects Successfully Saved!"
        className={classes.titletyp}
      />
      <SaveMessageErr
        responseMessage="Error, check the values entered!"
        className={classes.titletyp}
      />
      <DuplicateMessageErr
      responseMessage="Error, Duplicate record!"
      className={classes.titletyp}
    />
    </>
  );
}
