import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AppBar from "./components/AppBar";
import NewRedir from "./components/NewRedir";
import BulkRedir from "./components/BulkRedir";
import ManageRedir from "./components/ManageRedir";
import ViewHis from "./components/ViewHis";
import LoginComp from "./components/LoginComp";
import AuthProviderWithHistory from "./auth0-provider-with-history";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isAuthenticated } = useAuth0();

  console.log (sessionStorage.getItem('isAuthenticated'));

  return (
    <BrowserRouter>
      <div>
        <AppBar />
      </div>
      <AuthProviderWithHistory>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              (sessionStorage.getItem('isAuthenticated') === 'true') ? (
                <Route component={NewRedir} />
              ) : (
                <Route component={LoginComp} />
              )
            }
          />
          <Route
            exact
            path="/newredir"
            render={() =>
              (sessionStorage.getItem('isAuthenticated') === 'true') ? (
                <Route component={NewRedir} />
              ) : (
                <Route component={LoginComp} />
              )
            }
          />
          <Route
            exact
            path="/bulkredir"
            render={() =>
              (sessionStorage.getItem('isAuthenticated') === 'true') ? (
                <Route component={BulkRedir} />
              ) : (
                <Route component={LoginComp} />
              )
            }
          />
          <Route
            exact
            path="/manageredir"
            render={() =>
              (sessionStorage.getItem('isAuthenticated') === 'true') ? (
                <Route component={ManageRedir} />
              ) : (
                <Route component={LoginComp} />
              )
            }
          />
          <Route
          exact
          path="/viewhistory"
          render={() =>
            (sessionStorage.getItem('isAuthenticated') === 'true') ? (
              <Route component={ViewHis} />
            ) : (
              <Route component={LoginComp} />
            )
          }
        />
        </Switch>
      </AuthProviderWithHistory>
    </BrowserRouter>
  );
}

export default App;

// {      <Route exact path="/viewhistory" component={ViewHis}/>}
