import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { array } from "prop-types";
import Chip from "@material-ui/core/Chip";
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 100,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    flexGrow: 1,
    marginLeft: "275px",
    width: "77%",
    marginTop: "20px",
    borderRadius: 10,
  },
  titletyp: {
    marginLeft: "275px",
    marginTop: "80px",
    fontSize: "1rem",
    fontWeight: "1000",
  },
  titletypcsv: {
    marginLeft: "275px",
    fontSize: ".75rem",
    fontStyle: "italic",
    fontWeight: "1000",
  },
  texttyp: {
    marginLeft: "275px",
    marginTop: "18px",
    fontSize: ".85rem",
    fontWeight: "0",
  },
  titletyperror: {
    marginLeft: "275px",
    marginTop: "10px",
    fontSize: ".75rem",
    fontWeight: "0",
  },
  chipstyle: {
    marginLeft: "20px",
    fontWeight: "800",
    margin: theme.spacing(0.5),
    marginTop: "-50px",
  },
  divsstyle: {
    marginLeft: "250px",
    marginTop: "19px",
  },
  butstyle: {
    marginLeft: "500px",
    fontWeight: "800",
    margin: theme.spacing(0.5),
    marginTop: "-50px",
  },
}));

export default function BulkRedir() {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [failopen, setfailOpen] = useState(false);
  const [fileerropen, setFileerropen] = useState(false);
  const [domainerrorsDisplay, setDomainerrorsDisplay] = useState();
  const [oldurlerrorsDisplay, setOldurlerrorsDisplay] = useState();
  const [newurlerrorsDisplay, setNewurlerrorsDisplay] = useState();
  const [hostError, sethostError] = useState(false);
  const [pathError, setpathError] = useState(false);
  const [redirectError, setredirectError] = useState(false);
  const [duplicateerrorsDisplay, setDuplicateerrorsDisplay] = useState();
  const [errorParentState, setErrorParentState] = useState(false);
  const [callFunctionState, setCallFunctionState] = useState(false);
  var rowIndex = 0;
  var errorCount = 0;

  var errorParent = false;
  const getURL =
    "https://qivnajm516.execute-api.eu-west-2.amazonaws.com/default/redirectsmanage";

  const startRequestData = `{"RequestItems": {"redirects_intermediate": [`;

  const endRequestData = `]}}`;

  var fileName = "";

  const browseUpload = (event) => {
    const reader = new FileReader();
    setFile(event.target.files[0]);
    reader.onload = fileLoad;
    reader.readAsText(event.target.files[0]);
    fileName = event.target.files[0].name;
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function UploadMessage(props) {
    const responseMessage = props.responseMessage;
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
        >
          {responseMessage}
        </Alert>
      </Snackbar>
    );
  }

  function UploadFailMessage(props) {
    const responsefailMessage = props.responsefailMessage;
    return (
      <Snackbar
        open={failopen}
        autoHideDuration={6000}
        onClose={() => {
          setfailOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setfailOpen(false);
          }}
          severity="error"
        >
          {responsefailMessage}
        </Alert>
      </Snackbar>
    );
  }

  // function FileErrorMessage(props) {
  //   const responsefailMessage = props.responsefailMessage;
  //   return (
  //     <Snackbar
  //       open={fileerropen}
  //       autoHideDuration={6000}
  //       onClose={() => {
  //         setFileerropen(false);
  //       }}
  //     >
  //       <Alert
  //         onClose={() => {
  //           setfailOpen(false);
  //         }}
  //         severity="error"
  //       >
  //         {responsefailMessage}
  //       </Alert>
  //     </Snackbar>
  //   );
  // }

  // useEffect(() => {
  //   console.log(requestData);

  // }, [requestData]);

  const fileLoad = (event) => {
    const fileBody = event.target.result;
    const data = {
      fileName,
      fileBody,
    };

    //Validation
    var allRows = fileBody.split(/\r?\n|\r/);
    const arrayMap = allRows.map((rowX) => rowX.split(","));

    function sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    }

    ///

    for (var zx = 1; zx < arrayMap.length; zx++) {
      for (var yx = 1; yx < arrayMap.length; yx++) {
        if (typeof arrayMap[zx] !== "undefined") {
          if (zx !== yx) {
            if (
              arrayMap[zx][1] === arrayMap[yx][1] &&
              arrayMap[zx][2] === arrayMap[yx][2]
            ) {
              errorParent = true;
              setErrorParentState(true);
              setDuplicateerrorsDisplay(
                "The file has duplicate items, please remove duplicates and upload the file again!"
              );
              setfailOpen(true);
              errorCount++;
              arrayMap.splice(yx, 1);
            }
          }
        }
      }
    }

    for (var xx = 1; xx < arrayMap.length; xx++) {
      if (
        arrayMap[xx][1] === undefined ||
        arrayMap[xx][2] === undefined ||
        arrayMap[xx][3] === undefined
      ) {
        // errorParent = true;
        // setDuplicateerrorsDisplay(
        //   "The file has empty items, please fix and upload the file again!"
        // );
        // setfailOpen(true);
        arrayMap.splice(xx, 1);
      }
    }

    var searchArray = [];

    axios
      .get(getURL, {
        headers: {
          "x-api-key": `36DwOcLS741oAYtbcOZ7S3S5SjlwZLse4tt9u5Wa`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          console.log("empty", response);
        } else {
          if (response.data) {
            searchArray = [...response.data];
            for (var ax = 1; ax < searchArray.length; ax++) {
              for (var bx = 1; bx < arrayMap.length; bx++) {
                if (typeof searchArray[ax] !== "undefined") {
                  if (ax !== bx) {
                    //console.log(searchArray[ax].host, arrayMap[bx][1]);
                    if (
                      searchArray[ax].host === arrayMap[bx][1] &&
                      searchArray[ax].path === arrayMap[bx][2]
                    ) {
                      errorParent = true;
                      setErrorParentState(true);
                      setDuplicateerrorsDisplay(
                        "The file has duplicate items, please remove duplicates and upload the file again!"
                      );
                      setfailOpen(true);
                      errorCount++;
                      //console.log(errorCount);
                    }
                  }
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log("empty", error);
      });

    ///

    var promiseArray = [];
    var rowIndexInterim = 0;
    var newPromise = "";
    var fruits = [];
    var fruitsold = [];
    var fruitsnew = [];
    var requestConstant = "";
    var id_intermediate = 0;
    var rowLimit = 0;
    const bulkdynamourl =
      "https://2rb2gpo521.execute-api.eu-west-2.amazonaws.com/default/redirectsbulkcommit";
    var bulkBody = "";
    var lastItem = arrayMap.length;

    for (rowIndex = 0; rowIndex < arrayMap.length; rowIndex++) {
      for (var colIndex = 0; colIndex < arrayMap[rowIndex].length; colIndex++) {
        //reset
        sethostError(false);
        setpathError(false);
        setredirectError(false);

        //Domain
        if (colIndex === 1 && rowIndex !== 0) {
          var regdtest = arrayMap[rowIndex][colIndex].search(
            /^(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))$/g
          );
          if (regdtest < 0) {
            setfailOpen(true);
            setFileerropen(true);
            sethostError(true);
            errorParent = true;
            setErrorParentState(true);
            errorCount++;
            var errMessageContent =
              "Error: Row: " +
              rowIndex +
              " Invalid Domain: " +
              arrayMap[rowIndex][colIndex] +
              "\n";
            fruits.push(errMessageContent);
          }
        }

        //Old URL
        if (colIndex === 2 && rowIndex !== 0) {
          var regdtestold = arrayMap[rowIndex][colIndex].search(
            /^\/[/.a-zA-Z0-9-]+$/g
          );
          if (regdtestold < 0) {
            setfailOpen(true);
            setFileerropen(true);
            setpathError(true);
            errorParent = true;
            setErrorParentState(true);
            errorCount++;
            var errMessageContentold =
              "Error: Row: " +
              rowIndex +
              " Invalid Path: " +
              arrayMap[rowIndex][colIndex] +
              "\n";
            fruitsold.push(errMessageContentold);
          }
        }

        //New URL
        if (colIndex === 3 && rowIndex !== 0) {
          var regdtestnew = arrayMap[rowIndex][colIndex].search(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
          );
          if (regdtestnew < 0) {
            setfailOpen(true);
            setFileerropen(true);
            setredirectError(true);
            errorParent = true;
            setErrorParentState(true);
            errorCount++;
            var errMessageContentnew =
              "Error: Row: " +
              rowIndex +
              " Invalid New URL: " +
              arrayMap[rowIndex][colIndex] +
              "\n";
            fruitsnew.push(errMessageContentnew);
          }
        }
      }

      if (rowIndex !== 0) {
        if (
          !hostError &&
          !pathError &&
          !redirectError &&
          arrayMap[rowIndex][1] !== undefined &&
          arrayMap[rowIndex][2] !== undefined &&
          arrayMap[rowIndex][3] !== undefined
        ) {
          if (rowIndex / 25 - Math.floor(rowIndex / 25) !== 0) {
            id_intermediate = Math.floor(
              Math.random() * (99999999 - 99999) + 99999 + 7
            );

            if (rowIndex === lastItem - 1) {
              requestConstant = requestConstant.concat(
                `{"PutRequest": {"Item": {  "id_intermediate":{ "N": "` +
                  id_intermediate +
                  `"}, "host":{ "S": "` +
                  arrayMap[rowIndex][1] +
                  `"}, "path":{ "S": "` +
                  arrayMap[rowIndex][2] +
                  `"}, "redirectURL":{ "S": "` +
                  arrayMap[rowIndex][3] +
                  `"}, "ruleName":{ "S": "` +
                  arrayMap[rowIndex][1] +
                  arrayMap[rowIndex][2] +
                  `"},"interim":{"S":"true"} }}}`
              );
              bulkBody = startRequestData.concat(
                requestConstant,
                endRequestData
              );
              //console.log("one", rowIndex);
              promiseArray.push(bulkBody);
              //console.log(bulkBody);
              requestConstant = "";
            } else {
              requestConstant = requestConstant.concat(
                `{"PutRequest": {"Item": {  "id_intermediate":{ "N": "` +
                  id_intermediate +
                  `"}, "host":{ "S": "` +
                  arrayMap[rowIndex][1] +
                  `"}, "path":{ "S": "` +
                  arrayMap[rowIndex][2] +
                  `"}, "redirectURL":{ "S": "` +
                  arrayMap[rowIndex][3] +
                  `"}, "ruleName":{ "S": "` +
                  arrayMap[rowIndex][1] +
                  arrayMap[rowIndex][2] +
                  `"},"interim":{"S":"true"} }}},`
              );
            }
          }

          if (!(rowIndex / 25 - Math.floor(rowIndex / 25) !== 0)) {
            id_intermediate = Math.floor(
              Math.random() * (99999999 - 99999) + 99999 + 7
            );
            if (rowIndex === lastItem - 1) {
              requestConstant = requestConstant.concat(
                `{"PutRequest": {"Item": {  "id_intermediate":{ "N": "` +
                  id_intermediate +
                  `"}, "host":{ "S": "` +
                  arrayMap[rowIndex][1] +
                  `"}, "path":{ "S": "` +
                  arrayMap[rowIndex][2] +
                  `"}, "redirectURL":{ "S": "` +
                  arrayMap[rowIndex][3] +
                  `"}, "ruleName":{ "S": "` +
                  arrayMap[rowIndex][1] +
                  arrayMap[rowIndex][2] +
                  `"}}}}`
              );
            } else {
              requestConstant = requestConstant.concat(
                `{"PutRequest": {"Item": {  "id_intermediate":{ "N": "` +
                  id_intermediate +
                  `"}, "host":{ "S": "` +
                  arrayMap[rowIndex][1] +
                  `"}, "path":{ "S": "` +
                  arrayMap[rowIndex][2] +
                  `"}, "redirectURL":{ "S": "` +
                  arrayMap[rowIndex][3] +
                  `"}, "ruleName":{ "S": "` +
                  arrayMap[rowIndex][1] +
                  arrayMap[rowIndex][2] +
                  `"}}}}`
              );
            }
            //console.log("three", rowIndex);
            bulkBody = startRequestData.concat(requestConstant, endRequestData);
            promiseArray.push(bulkBody);
            //console.log(bulkBody);
            requestConstant = "";
          }
        }
      }
      rowLimit++;

      //inside validate
      if (lastItem / 25 > 1 && rowIndex / 25 === 1) {
        if (!hostError && !pathError && !redirectError) {
          id_intermediate = Math.floor(
            Math.random() * (99999999 - 99999) + 99999 + 7
          );

          //   rowIndexInterim = rowIndex;
          //   rowIndexInterim++;
          //  // console.log(rowIndexInterim);
          //   requestConstant = requestConstant.concat(
          //     `{"PutRequest": {"Item": {  "id_intermediate":{ "N": "` +
          //       id_intermediate +
          //       `"}, "host":{ "S": "` +
          //       arrayMap[rowIndexInterim][1] +
          //       `"}, "path":{ "S": "` +
          //       arrayMap[rowIndexInterim][2] +
          //       `"}, "redirectURL":{ "S": "` +
          //       arrayMap[rowIndexInterim][3] +
          //       `"}, "ruleName":{ "S": "` +
          //       arrayMap[rowIndexInterim][1] +
          //       arrayMap[rowIndexInterim][2] +
          //       `"}}}}`
          //   );
          // bulkBody = startRequestData.concat(requestConstant, endRequestData);
          // console.log("inside Validate");
          // // console.log(bulkBody);
          // // newPromise = axios({
          // //   method: "post",
          // //   headers: {
          // //     "x-api-key": `XxuYdia6eAanT3nv2zgaV41ngBkRCCGr5lXXIAXW`
          // //   },
          // //   url: bulkdynamourl,
          // //   data: bulkBody
          // // });
          // promiseArray.push(bulkBody);

          // axios
          //   .post(bulkdynamourl, bulkBody, {
          //     headers: {
          //       "x-api-key": `XxuYdia6eAanT3nv2zgaV41ngBkRCCGr5lXXIAXW`
          //     }
          //   })
          //   .then(response => {
          //     if (response.status !== 200) {
          //       console.log("error");
          //       setfailOpen(true);
          //     } else {
          //       setOpen(true);
          //     }
          //   })
          //   .catch(error => {
          //     console.log("error");
          //     setfailOpen(true);
          //   });

          // // sleep(5000);
          // rowLimit = 0;
          // requestConstant = "";
        }
      }

      //inside
      if (lastItem / 25 === 1 && rowLimit / 25 === 1) {
        if (!hostError && !pathError && !redirectError) {
          // id_intermediate = Math.floor(Math.random()*(99999999 - 99999) + 99999 + 7);
          // requestConstant = requestConstant.concat(`{"PutRequest": {"Item": {  "id_intermediate":{ "N": "`+id_intermediate+`"}, "host":{ "S": "`+arrayMap[rowIndex][1]+`"}, "path":{ "S": "`+arrayMap[rowIndex][2]+`"}, "redirectURL":{ "S": "`+arrayMap[rowIndex][3]+`"}, "ruleName":{ "S": "`+arrayMap[rowIndex][1]+arrayMap[rowIndex][2]+`"}}}}`);
          // bulkBody = startRequestData.concat(requestConstant, endRequestData);
          // console.log("inside ===");
          // // console.log(bulkBody);
          // promiseArray.push(bulkBody);
          // axios
          //   .post(bulkdynamourl, bulkBody, {
          //     headers: {
          //       "x-api-key": `XxuYdia6eAanT3nv2zgaV41ngBkRCCGr5lXXIAXW`
          //     }
          //   })
          //   .then(response => {
          //     if (response.status !== 200) {
          //       console.log("error");
          //       setfailOpen(true);
          //     } else {
          //       setOpen(true);
          //     }
          //   })
          //   .catch(error => {
          //     console.log("error");
          //     setfailOpen(true);
          //   });
          // rowLimit = 0;
          // requestConstant = "";
        }
      }

      setCallFunctionState(true);
    }
    //outside
    if (lastItem / 25 > 1 && rowLimit / 25 < 1) {
      if (!hostError && !pathError && !redirectError) {
        // id_intermediate = Math.floor(Math.random()*(99999999 - 99999) + 99999 + 7);
        // requestConstant = requestConstant.concat(`{"PutRequest": {"Item": {  "id_intermediate":{ "N": "`+id_intermediate+`"}, "host":{ "S": "`+arrayMap[rowIndex][1]+`"}, "path":{ "S": "`+arrayMap[rowIndex][2]+`"}, "redirectURL":{ "S": "`+arrayMap[rowIndex][3]+`"}, "ruleName":{ "S": "`+arrayMap[rowIndex][1]+arrayMap[rowIndex][2]+`"}}}}`);
        // bulkBody = startRequestData.concat(requestConstant, endRequestData);
        // // bulkdynamodata = {bulkBody};
        // // ---- uncomment below --
        // console.log("outside lastitem > 1");
        // //console.log(bulkBody);
        // promiseArray.push(bulkBody);
        // // axios
        // //   .post(bulkdynamourl, bulkBody, {
        // //     headers: {
        // //       "x-api-key": `XxuYdia6eAanT3nv2zgaV41ngBkRCCGr5lXXIAXW`
        // //     }
        // //   })
        // //   .then(response => {
        // //     if (response.status !== 200) {
        // //       console.log("error");
        // //       setfailOpen(true);
        // //     } else {
        // //       setOpen(true);
        // //     }
        // //   })
        // //   .catch(error => {
        // //     console.log("error");
        // //     setfailOpen(true);
        // //   });
        // rowLimit = 0;
        // requestConstant = "";
      }
    }

    //outside
    if (lastItem / 25 < 1 && rowLimit / 25 < 1) {
      if (!hostError && !pathError && !redirectError) {
        // id_intermediate = Math.floor(Math.random()*(99999999 - 99999) + 99999 + 7);
        // requestConstant = requestConstant.concat(`{"PutRequest": {"Item": {  "id_intermediate":{ "N": "`+id_intermediate+`"}, "host":{ "S": "`+arrayMap[rowIndex][1]+`"}, "path":{ "S": "`+arrayMap[rowIndex][2]+`"}, "redirectURL":{ "S": "`+arrayMap[rowIndex][3]+`"}, "ruleName":{ "S": "`+arrayMap[rowIndex][1]+arrayMap[rowIndex][2]+`"}}}}`);
        // bulkBody = startRequestData.concat(requestConstant, endRequestData);
        // // bulkdynamodata = {bulkBody};
        // console.log("outside lastitem < 1");
        // // console.log(bulkBody);
        // promiseArray.push(bulkBody);
        // // axios
        // //   .post(bulkdynamourl, bulkBody, {
        // //     headers: {
        // //       "x-api-key": `XxuYdia6eAanT3nv2zgaV41ngBkRCCGr5lXXIAXW`
        // //     }
        // //   })
        // //   .then(response => {
        // //     if (response.status !== 200) {
        // //       console.log("error");
        // //       setfailOpen(true);
        // //     } else {
        // //       setOpen(true);
        // //     }
        // //   })
        // //   .catch(error => {
        // //     console.log("error");
        // //     setfailOpen(true);
        // //   });
        // rowLimit = 0;
        // requestConstant = "";
      }
    }

    //console.log(JSON.stringify(promiseArray));

    setTimeout(
      function () {
        console.log("timeout...5 seconds");
        console.log(errorCount);
        if (errorCount <= 0) {
          axios
            .post(bulkdynamourl, promiseArray, {
              headers: {
                "x-api-key": `XxuYdia6eAanT3nv2zgaV41ngBkRCCGr5lXXIAXW`,
              },
            })
            .then((response) => {
              if (response.status !== 200) {
                console.log("error", response);
                setfailOpen(true);
              } else {
                setOpen(true);
              }
            })
            .catch((error) => {
              console.log("error here ", error);
              setfailOpen(true);
            });
        }
      }.bind(this),
      5000
    );

    var fruitsstr = fruits.toString();

    if (fruitsstr) {
      setDomainerrorsDisplay(fruitsstr);
    }

    var fruitsstrold = fruitsold.toString();

    if (fruitsstrold) {
      setOldurlerrorsDisplay(fruitsstrold);
    }

    var fruitsstrnew = fruitsnew.toString();

    if (fruitsstrnew) {
      setNewurlerrorsDisplay(fruitsstrnew);
    }

    // if (!fruitsstr && !fruitsstrold && !fruitsstrnew){

    // //Upload to S3
    // const url = "https://gkwmgcmvn0.execute-api.eu-west-2.amazonaws.com/default/bulkupload";
    // axios.post(url, data, {
    //   headers: {
    //     'x-api-key': `aCc7yQNL9j6xJRSeHXr1l569mITJr75Z8ZVnR5i6`
    //   },
    // })
    // .then ((response)=> {

    //   if (response.status !== 200){
    //   console.log("error");
    //   setfailOpen(true);
    //   } else
    //   {
    //       setOpen(true);
    //   };

    // })
    // .catch((error)=>{
    //   console.log("error");
    //   setfailOpen(true);
    // })

    // }
  };

  return (
    <>
      <Typography gutterBottom className={classes.titletyp}>
        Bulk Uploads
      </Typography>
      <Divider variant="middle" className={classes.divsstyle} />

      <Typography gutterBottom className={classes.texttyp}>
        Select and upload a valid .csv file
      </Typography>

      <div>
        <input
          accept="text/csv"
          type="file"
          style={{ display: "none" }}
          id="contained-button-file"
          onChange={browseUpload}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="default"
            startIcon={<CloudUploadIcon />}
            className={classes.butstyle}
            size="small"
            component="span"
          >
            Upload
          </Button>
        </label>
      </div>

      <UploadMessage
        responseMessage="File Successfully Uploaded!"
        className={classes.titletyp}
      />
      <UploadFailMessage
        responsefailMessage="Invalid File! Upload File Again!"
        className={classes.titletyp}
      />

      <Toolbar />
      <Chip icon={<InfoIcon/>} color="primary" label=".csv File Guidelines:" style={{marginLeft:"265px"}}/>
      <Typography  color="white"> .. </Typography>
      <Typography gutterBottom className={classes.titletypcsv}>
       1. Title (Row 1): column(0): S.No -- column(1): Domain -- column(2): Path
        -- column(3): Redirect URL
      </Typography>
      <Typography gutterBottom className={classes.titletypcsv}>
        2. Content (Rows 2 to ∞): column(0): S.No -- column(1): Domain
        (exaple.exampledomain.co.uk) -- column(2): Path (/path/routing/page) --
        column(3): Redirect URL (https://www.exampledomain.co.uk/path/new/page)
      </Typography>
      <Typography  color="white"> .. </Typography>
      <Chip icon={<WarningIcon/>} color="secondary" label="Common Errors:" style={{marginLeft:"265px"}}/>
      <Typography  color="white"> .. </Typography>
      <Typography gutterBottom className={classes.titletypcsv}>
        A. Space at the begining, end or in-between the fields
      </Typography>
      <Typography gutterBottom className={classes.titletypcsv}>
        B. Blank lines in the file
      </Typography>
      <Typography gutterBottom className={classes.titletypcsv}>
        C. Invalid .csv file format i.e. additional commas, no commas etc
      </Typography>
      <Typography gutterBottom className={classes.titletypcsv}>
        D. Invalid Title or Content i.e. not per format mentioned in the above guidelines
      </Typography>

      <Divider variant="middle" className={classes.divsstyle} />

      <Typography
        align="justify"
        className={classes.titletyperror}
        color="error"
        display="block"
        paragraph
      >
        {domainerrorsDisplay}
      </Typography>
      <Typography
        align="justify"
        className={classes.titletyperror}
        color="error"
        display="block"
        paragraph
      >
        {oldurlerrorsDisplay}
      </Typography>
      <Typography
        align="justify"
        className={classes.titletyperror}
        color="error"
        display="block"
        paragraph
      >
        {newurlerrorsDisplay}
      </Typography>
      <Typography
        align="justify"
        className={classes.titletyperror}
        color="error"
        display="block"
        paragraph
      >
        {duplicateerrorsDisplay}
      </Typography>
    </>
  );
}
