import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import EnhancedTableHistory from "./EnhancedTableHistory";
import axios from "axios";


const getURL =
  "https://7a8sa4274a.execute-api.eu-west-2.amazonaws.com/default/redirectshistory";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 100
  },
  titletyp: {
    marginLeft: "275px",
    marginTop: "80px",
    fontSize: "1rem",
    fontWeight: "1000"
  },
  texttyp: {
    marginLeft: "275px",
    marginTop: "18px",
    fontSize: ".85rem",
    fontWeight: "0"
  },
  divsstyle: {
    marginLeft: "250px",
    marginTop: "19px"
  },
  textfieldcolor: {
    borderColor: "black"
  },
  label: {
    "&$focused": {
      color: "#000000"
    }
  },
  focused: {},
  outlinedInput: {
    "&$focused $notchedOutline": {
      border: "1px solid #000000"
    }
  },
  notchedOutline: {},
  fabstyle: {
    margin: theme.spacing(1),
    position: "fixed",
    marginLeft: "870px",
    marginTop: "780px",
    fontWeight: "1000",
    fontSize: ".75rem",
    backgroundColor: "black",
    textTransform: "none"
  },
  fabstylesave: {
    margin: theme.spacing(1),
    position: "fixed",
    marginLeft: "25px",
    marginTop: "23px",
    fontWeight: "1000",
    fontSize: ".75rem",
    backgroundColor: "black",
    textTransform: "none"
  },
  fabstylegen: {
    margin: theme.spacing(1),
    position: "fixed",
    marginLeft: "175px",
    marginTop: "23px",
    fontWeight: "1000",
    fontSize: ".75rem",
    backgroundColor: "green",
    textTransform: "none"
  },
  extendedIcon: {
    color: "white"
  },
  extendedsaveIcon: {
    color: "black"
  }
}));

export default function ManageRedir() {
  const classes = useStyles();
  //const [addMaster, setAddMaster] = React.useState(false);
  const [addfabEnable, setAddfabEnable] = React.useState(false);
  const [refEnhanced, setRefEnhanced] = React.useState([]);
  const searchArray = [];

  var searchResults = [];
  const [firstSearch, setFirstSearch] = React.useState(false);


  useEffect(() => {
    axios
      .get(getURL, {
        headers: {
          "x-api-key": `izRyaKziMF84nfLupK6R21YcMcusk9bq4IvI9YX7`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error", response);
        } else {
          if (response.data.length < 1) {
            setAddfabEnable(true);
          }
          searchArray.push(response.data);
        }
      })
      .catch(error => {
        console.log("error here ", error);
      });
  }, []);

  function toUsestate() {
    axios
      .get(getURL, {
        headers: {
          "x-api-key": `izRyaKziMF84nfLupK6R21YcMcusk9bq4IvI9YX7`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error", response);
        } else {
          if (response.data.length < 1) {
            setAddfabEnable(true);
          }
          console.log("ah here");
        }
      })
      .catch(error => {
        console.log("error here ", error);
      });
  }

  window.beforeunload = e => {
    toUsestate();
  };


  const searchFunction = event => {
    const searchValue = event.target.value;
    var searchString = "";
    var arrayBool = false;

    console.log(searchArray);
    if (!Array.isArray(searchArray) || !searchArray.length) {
      //console.log(searchArray[0].length);
    } else {
      console.log(searchArray[0].length);
      for (var i = 0; i < searchArray[0].length; i++) {
        searchString = "";
        if (searchArray[0][i].id_intermediate) {
          searchString = searchArray[0][i].id_intermediate.toString();
          if (searchString.search(searchValue) >= 0 && !arrayBool) {
            searchResults.push(searchArray[0][i]);
            arrayBool = true;
          } else {
            console.log("");
          }
        }

        searchString = "";
        if (searchArray[0][i].host) {
          searchString = searchArray[0][i].host.toString();
          if (searchString.search(searchValue) >= 0 && !arrayBool) {
            searchResults.push(searchArray[0][i]);
            arrayBool = true;
          } else {
            console.log("");
          }
        }

        searchString = "";
        if (searchArray[0][i].interim) {
          searchString = searchArray[0][i].interim.toString();
          if (searchString.search(searchValue) >= 0 && !arrayBool) {
            searchResults.push(searchArray[0][i]);
            arrayBool = true;
          } else {
            console.log("");
          }
        }

        searchString = "";
        if (searchArray[0][i].ruleName) {
          searchString = searchArray[0][i].ruleName.toString();
          if (searchString.search(searchValue) >= 0 && !arrayBool) {
            searchResults.push(searchArray[0][i]);
            arrayBool = true;
          } else {
            console.log("");
          }
        }

        searchString = "";
        if (searchArray[0][i].path) {
          searchString = searchArray[0][i].path.toString();
          if (searchString.search(searchValue) >= 0 && !arrayBool) {
            searchResults.push(searchArray[0][i]);
            arrayBool = true;
          } else {
            console.log("");
          }
        }
        arrayBool = false;
      }
      console.log(searchResults);
      //window.location.reload(false);
      setRefEnhanced({ searchResults });
      setFirstSearch(true);
    }
  };

  return (
    <>
      <Typography gutterBottom className={classes.titletyp}>
        All Master Records
      </Typography>
      <Divider variant="middle" className={classes.divsstyle} />
      <EnhancedTableHistory searchResultsP={refEnhanced} />

    </>
  );
}
