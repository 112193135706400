import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import BackupIcon from "@material-ui/icons/Backup";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
    marginTop: 60,
    marginLeft: 0
  },
  item: {
    color: "black",
    "& span, & svg": {
      fontSize: "0.8rem",
      fontWeight: "50"
    }
  },
  adminitem: {
    color: "black",
    "& span, & svg": {
      fontSize: "0.85rem",
      fontWeight: "1000"
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

export default function SimpleList() {
  const classes = useStyles();
  const history = useHistory();

  const newRedir = () => {
    let path = `newredir`;
    history.push(path);
  };

  const bulkRedir = () => {
    let path = `bulkredir`;
    history.push(path);
  };

  const manageRedir = () => {
    let path = `manageredir`;
    history.push(path);
  };

  const viewHistory = () => {
    let path = `viewhistory`;
    history.push(path);
  };

  const adminPanel = () => {
    let path = `/`;
    history.push(path);
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List component="nav">
            <ListItem button onClick={adminPanel} disabled="true">
              <ListItemText
                primary="Admin Panel"
                className={classes.adminitem}
                style={{
                  marginLeft: "35px",
                  paddingBottom: "0px",
                  paddingTop: "0px"
                }}
              />
            </ListItem>
          </List>
          <Divider />
          <List component="nav">
            <ListItem button onClick={newRedir}>
              <ListItemIcon style={{ minWidth: "30px", color: "black" }}>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary="Create New Redirects"
                className={classes.item}
              />
            </ListItem>
            <ListItem button onClick={bulkRedir}>
              <ListItemIcon style={{ minWidth: "30px", color: "black" }}>
                <BackupIcon />
              </ListItemIcon>
              <ListItemText
                primary="Bulk Upload Redirects"
                className={classes.item}
              />
            </ListItem>
            <ListItem button onClick={manageRedir}>
              <ListItemIcon style={{ minWidth: "30px", color: "black" }}>
                <EditIcon />
              </ListItemIcon>
              <ListItemText
                primary="Review Redirects"
                className={classes.item}
              />
            </ListItem>
            <ListItem button onClick={viewHistory}>
              <ListItemIcon style={{ minWidth: "30px", color: "black" }}>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary="View History" className={classes.item} />
            </ListItem>
            ;
          </List>
        </div>
      </Drawer>
    </div>
  );
}

// {
// <ListItem button onClick={viewHistory}>
//   <ListItemIcon style={{ minWidth: "30px", color: "black" }}>
//     <HistoryIcon />
//   </ListItemIcon>
//   <ListItemText primary="View History" className={classes.item} />
// </ListItem>;
// }
