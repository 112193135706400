import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AuthProviderWithHistory from "./auth0-provider-with-history";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <Router>
    <AuthProviderWithHistory>
      <App />
    </AuthProviderWithHistory>
  </Router>,
  document.getElementById("root")
);
