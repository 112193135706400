import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button
      onClick={() =>
        logout({
          returnTo: window.location.origin
        })
      }
      size="small"
      color="primary"
      style={{ color: "white", marginLeft: "135px", background: "black" }}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
