import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import EnhancedTable from "./EnahncedTable";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ConsoleWriter } from "istanbul-lib-report";

const addMasterURL =
  "https://f06lw9i8gf.execute-api.eu-west-2.amazonaws.com/default/redirectsaddmaster";

const genMasterURL =
  "https://lak1s4rvce.execute-api.eu-west-2.amazonaws.com/default/redirectsgenerate";

const getURL =
  "https://qivnajm516.execute-api.eu-west-2.amazonaws.com/default/redirectsmanage";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 100
  },
  titletyp: {
    marginLeft: "275px",
    marginTop: "80px",
    fontSize: "1rem",
    fontWeight: "1000"
  },
  texttyp: {
    marginLeft: "275px",
    marginTop: "18px",
    fontSize: ".85rem",
    fontWeight: "0"
  },
  divsstyle: {
    marginLeft: "250px",
    marginTop: "19px"
  },
  textfieldcolor: {
    borderColor: "black"
  },
  label: {
    "&$focused": {
      color: "#000000"
    }
  },
  focused: {},
  outlinedInput: {
    "&$focused $notchedOutline": {
      border: "1px solid #000000"
    }
  },
  notchedOutline: {},
  fabstyle: {
    margin: theme.spacing(1),
    position: "fixed",
    marginLeft: "870px",
    marginTop: "780px",
    fontWeight: "1000",
    fontSize: ".75rem",
    backgroundColor: "black",
    textTransform: "none"
  },
  fabstylesave: {
    margin: theme.spacing(1),
    position: "fixed",
    marginLeft: "25px",
    marginTop: "23px",
    fontWeight: "1000",
    fontSize: ".75rem",
    backgroundColor: "black",
    textTransform: "none"
  },
  fabstylegen: {
    margin: theme.spacing(1),
    position: "fixed",
    marginLeft: "175px",
    marginTop: "23px",
    fontWeight: "1000",
    fontSize: ".75rem",
    backgroundColor: "green",
    textTransform: "none"
  },
  extendedIcon: {
    color: "white"
  },
  extendedsaveIcon: {
    color: "black"
  }
}));

export default function ManageRedir() {
  const classes = useStyles();
  const [fabEnable, setFabEnable] = React.useState(false);
  //const [addMaster, setAddMaster] = React.useState(false);
  const [addfabEnable, setAddfabEnable] = React.useState(false);
  const [refEnhanced, setRefEnhanced] = React.useState([]);
  const searchArray = [];
  const searchValue = "";
  const searchString = "";
  var searchResults = [];
  const [firstSearch, setFirstSearch] = React.useState(false);
  var groupMatch = "";
  var beaconRedirectsArray = [];
  var solasOrgRedirectsArray = [];
  var solasTakeIeltsRedirectsArray = [];
  var solasRedirectsArray = [];
  var ruleNameString = "";

  useEffect(() => {
    axios
      .get(getURL, {
        headers: {
          "x-api-key": `36DwOcLS741oAYtbcOZ7S3S5SjlwZLse4tt9u5Wa`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error", response);
        } else {
          if (response.data.length < 1) {
            setAddfabEnable(true);
          }
          searchArray.push(response.data);
          // console.log(response.data);
          console.log("super here");
          console.log(searchArray);
        }
      })
      .catch(error => {
        console.log("error here ", error);
      });
  }, []);

  function toUsestate() {
    axios
      .get(getURL, {
        headers: {
          "x-api-key": `36DwOcLS741oAYtbcOZ7S3S5SjlwZLse4tt9u5Wa`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error", response);
        } else {
          if (response.data.length < 1) {
            setAddfabEnable(true);
          }
          searchArray.push(response.data);
          console.log("ah here");
        }
      })
      .catch(error => {
        console.log("error here ", error);
      });
  }

  window.beforeunload = e => {
    toUsestate();
  };

  const handleAddMaster = event => {
    console.log("here");

    axios
      .get(addMasterURL, {
        headers: {
          "x-api-key": `POHPiXZ5xS9ie7AUmTH5x3pRc7t4W5Js8oSrZDmy`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error");
        } else {
          //setAddMaster(true);

          setFabEnable(true);
          setAddfabEnable(true);
          console.log("success");
        }
      })
      .catch(error => {
        console.log("error here ", error);
      });

    //window.location.reload(false);
  };

  // function Alert(props) {
  //   return <MuiAlert elevation={6} variant="filled" {...props} />;
  // }

  // function AddedMaster(props) {
  //   const responseMessage = props.responseMessage;
  //   return (
  //     <Snackbar
  //       open={addMaster}
  //       autoHideDuration={6000}
  //       onClose={() => {
  //         setAddMaster(false);
  //       }}
  //     >
  //       <Alert
  //         onClose={() => {
  //           setAddMaster(false);
  //         }}
  //         severity="success"
  //       >
  //         {responseMessage}
  //       </Alert>
  //     </Snackbar>
  //   );
  // }

  const generateScript = event => {
    //call endpoint
    var genArray = [];
    var forGenArray = [];
    var genConst = "";
    genArray.push(
      "ruleName,host,path,result.useIncomingQueryString,result.redirectURL,result.statusCode"
    );
    beaconRedirectsArray.push(
      "ruleName,host,path,result.useIncomingQueryString,result.redirectURL,result.statusCode"
    );
    solasOrgRedirectsArray.push(
      "ruleName,host,path,result.useIncomingQueryString,result.redirectURL,result.statusCode"
    );
    solasTakeIeltsRedirectsArray.push(
      "ruleName,host,path,result.useIncomingQueryString,result.redirectURL,result.statusCode"
    );
    solasRedirectsArray.push(
      "ruleName,host,path,result.useIncomingQueryString,result.redirectURL,result.statusCode"
    );

    var duplicateCheckArray1 = [];
    var duplicateCheckArray2 = [];

    axios
      .get(genMasterURL, {
        headers: {
          "x-api-key": `XBHeLhq7Sb67w4Xa5JkCz4RziPeHvidQ7EgftGvm`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error");
        } else {

          duplicateCheckArray1.push(response.data);
          duplicateCheckArray2.push(response.data);

          for (var zx = 0; zx < duplicateCheckArray1[0].length; zx++) {
            for (var yx = 0; yx < duplicateCheckArray2[0].length; yx++) {
              //if (typeof duplicateCheckArray1[0][zx] !== "undefined" && typeof duplicateCheckArray2[0][yx] !== "undefined") {
                if (zx !== yx) {
                  if (
                    duplicateCheckArray1[0][zx].host === duplicateCheckArray2[0][yx].host &&
                    duplicateCheckArray1[0][zx].path === duplicateCheckArray2[0][yx].path
                  ) {
                    duplicateCheckArray1[0].splice(yx, 1);
                  }
                }
              //}
            }
          }

          console.log(duplicateCheckArray1[0].length);
          ///// in the below logic you can replace dupliateCheckArray with response.data but it will have duplicates

          for (var i = 0; i < duplicateCheckArray1[0].length; i++) {
            //ruleName,host,path,result.useIncomingQueryString,result.redirectURL,result.statusCode
            // genConst = genConst.concat(
            //   "\n" +
            //     response.data[i].ruleName +
            //     "," +
            //     response.data[i].host +
            //     "," +
            //     response.data[i].path +
            //     ",1," +
            //     response.data[i].redirectURL +
            //     ",301"
            // );
            ruleNameString = ruleNameString.concat(
              duplicateCheckArray1[0][i].host,
              duplicateCheckArray1[0][i].path
            );
            // console.log(ruleNameString);

            genConst = genConst.concat(
              ruleNameString +
                "," +
                duplicateCheckArray1[0][i].host +
                "," +
                duplicateCheckArray1[0][i].path +
                ",1," +
                encodeURIComponent(duplicateCheckArray1[0][i].redirectURL) +
                ",301"
            );
            //genConst = genConst.concat("\n");
            ruleNameString = "";

            groupMatch = duplicateCheckArray1[0][i].host;

            if (
              groupMatch.search("film.britishcouncil.org") >= 0 ||
              groupMatch.search("beta-film.britishcouncil.org") >= 0
            ) {
              //BeaconRedirects
              beaconRedirectsArray.push(genConst);
            } else if (groupMatch.search("www.britishcouncil.org") >= 0) {
              //SolasOrgRedirects
              solasOrgRedirectsArray.push(genConst);
            } else if (groupMatch.search("takeielts.britishcouncil.org") >= 0) {
              //SolasTakeIeltsRedirects
              solasTakeIeltsRedirectsArray.push(genConst);
            } else {
              //SolasRedirects
              solasRedirectsArray.push(genConst);
            }

            // if (response.data[i].host)
            genArray.push(genConst);
            genConst = "";
            groupMatch = "";
          }
          //generate file

          // for (var k=0;k<genArray.length;k++){
          //   console.log(genArray[k]);
          // }
          //console.log(genArray);

          var genStitch = "";

          for (var k = 0; k < genArray.length; k++) {
            genStitch = genStitch.concat(genArray[k], "\n");
          }

          var genStitch1 = "";

          for (var k1 = 0; k1 < beaconRedirectsArray.length; k1++) {
            genStitch1 = genStitch1.concat(beaconRedirectsArray[k1], "\n");
          }

          var genStitch2 = "";

          for (var k2 = 0; k2 < solasOrgRedirectsArray.length; k2++) {
            genStitch2 = genStitch2.concat(solasOrgRedirectsArray[k2], "\n");
          }

          var genStitch3 = "";

          for (var k3 = 0; k3 < solasTakeIeltsRedirectsArray.length; k3++) {
            genStitch3 = genStitch3.concat(
              solasTakeIeltsRedirectsArray[k3],
              "\n"
            );
          }

          var genStitch4 = "";

          for (var k4 = 0; k4 < solasRedirectsArray.length; k4++) {
            genStitch4 = genStitch4.concat(solasRedirectsArray[k4], "\n");
          }

          //console.log(genStitch);

          const link = document.createElement("a");
          link.style.display = "none";
          document.body.appendChild(link);
          var blob = new Blob([genStitch], { type: "application/csv" });
          const objectURL = URL.createObjectURL(blob);

          link.href = objectURL;
          link.href = URL.createObjectURL(blob);
          link.download = "RedirectsScript.csv";
          link.click();

          const link1 = document.createElement("a");
          link1.style.display = "none";
          document.body.appendChild(link1);
          var blob1 = new Blob([genStitch1], { type: "application/csv" });
          const objectURL1 = URL.createObjectURL(blob);

          link.href = objectURL1;
          link.href = URL.createObjectURL(blob1);
          link.download = "beaconRedirects.csv";
          link.click();

          const link2 = document.createElement("a");
          link2.style.display = "none";
          document.body.appendChild(link2);
          var blob2 = new Blob([genStitch2], { type: "application/csv" });
          const objectURL2 = URL.createObjectURL(blob2);

          link.href = objectURL2;
          link.href = URL.createObjectURL(blob2);
          link.download = "solasOrgRedirects.csv";
          link.click();

          const link3 = document.createElement("a");
          link3.style.display = "none";
          document.body.appendChild(link3);
          var blob3 = new Blob([genStitch3], { type: "application/csv" });
          const objectURL3 = URL.createObjectURL(blob3);

          link.href = objectURL3;
          link.href = URL.createObjectURL(blob3);
          link.download = "solasTakeIeltsRedirects.csv";
          link.click();

          const link4 = document.createElement("a");
          link4.style.display = "none";
          document.body.appendChild(link4);
          var blob4 = new Blob([genStitch4], { type: "application/csv" });
          const objectURL4 = URL.createObjectURL(blob4);

          link.href = objectURL4;
          link.href = URL.createObjectURL(blob4);
          link.download = "solasRedirects.csv";
          link.click();
          //console.log(genArray);
        }
      })
      .catch(error => {
        console.log("error here ", error);
      });

    console.log("generating");
  };

  const handleMasterClose = event => {
    window.location.reload(false);
  };

  const handleMasterCloseConfirm = event => {
    //window.location.reload(false);
    setFabEnable(false);
    window.location.reload(false);
    //console.log("closed");
  };

  const searchFunction = event => {
    const searchValue = event.target.value;
    var searchString = "";
    var arrayBool = false;

    axios
    .get(getURL, {
      headers: {
        "x-api-key": `36DwOcLS741oAYtbcOZ7S3S5SjlwZLse4tt9u5Wa`
      }
    })
    .then(response => {
      if (response.status !== 200) {
        console.log("error", response);
      } else {
        
        searchArray.push(response.data);
        console.log(searchArray);
        if (!Array.isArray(searchArray) || !searchArray.length) {
          //console.log(searchArray[0].length);
        } else {
          console.log(searchArray[0].length);
          for (var i = 0; i < searchArray[0].length; i++) {
            searchString = "";
            if (searchArray[0][i].id_intermediate) {
              searchString = searchArray[0][i].id_intermediate.toString();
              if (searchString.search(searchValue) >= 0 && !arrayBool) {
                searchResults.push(searchArray[0][i]);
                arrayBool = true;
              } else {
                console.log("");
              }
            }
    
            searchString = "";
            if (searchArray[0][i].host) {
              searchString = searchArray[0][i].host.toString();
              if (searchString.search(searchValue) >= 0 && !arrayBool) {
                searchResults.push(searchArray[0][i]);
                arrayBool = true;
              } else {
                console.log("");
              }
            }
    
            searchString = "";
            if (searchArray[0][i].interim) {
              searchString = searchArray[0][i].interim.toString();
              if (searchString.search(searchValue) >= 0 && !arrayBool) {
                searchResults.push(searchArray[0][i]);
                arrayBool = true;
              } else {
                console.log("");
              }
            }
    
            searchString = "";
            if (searchArray[0][i].ruleName) {
              searchString = searchArray[0][i].ruleName.toString();
              if (searchString.search(searchValue) >= 0 && !arrayBool) {
                searchResults.push(searchArray[0][i]);
                arrayBool = true;
              } else {
                console.log("");
              }
            }
    
            searchString = "";
            if (searchArray[0][i].path) {
              searchString = searchArray[0][i].path.toString();
              if (searchString.search(searchValue) >= 0 && !arrayBool) {
                searchResults.push(searchArray[0][i]);
                arrayBool = true;
              } else {
                console.log("");
              }
            }
            arrayBool = false;
          }
          console.log(searchResults);
          //window.location.reload(false);
          setRefEnhanced({ searchResults });
          setFirstSearch(true);
        }
      }
    })
    .catch(error => {
      console.log("error here ", error);
    });

   
  };

  return (
    <>
      <Typography gutterBottom className={classes.titletyp}>
        Review Redirects
      </Typography>
      <Divider variant="middle" className={classes.divsstyle} />
      <TextField
        label="Search"
        id="card-date"
        className={classes.texttyp}
        variant="outlined"
        margin="dense"
        onKeyPress={ev => {
          if (ev.key === "Enter") {
            if (!firstSearch) {
              searchFunction(ev);
            } else {
              window.location.reload(false);
            }
          }
        }}
        onKeyDown={ev => {
          if (ev.key === "Delete" || ev.key === "Backspace") {
            window.location.reload(false);
          }
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.label,
            focused: classes.focused
          }
        }}
        InputProps={{
          classes: {
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline
          }
        }}
      />
      <Fab
        variant="extended"
        size="small"
        aria-label="Generate"
        className={classes.fabstylesave}
        style={{ color: "white", borderRadius: 5 }}
        onClick={handleAddMaster}
        disabled={addfabEnable}
      >
        <AddCircleIcon className={classes.extendedIcon} />
        Add to Master
      </Fab>
      <Fab
        variant="extended"
        size="small"
        aria-label="Generate"
        className={classes.fabstylegen}
        style={{ color: "white", borderRadius: 5 }}
        onClick={generateScript}
      >
        <DescriptionIcon className={classes.extendedIcon} />
        Generate Script
      </Fab>
      <EnhancedTable searchResultsP={refEnhanced} />
      <Dialog
        open={fabEnable}
        onClose={handleMasterClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Records to Master"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Records added to master, generate script to process redirects.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMasterCloseConfirm} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
