import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";

function createData(
  id_intermediate,
  old_url,
  path,
  redirect_url,
  isDelete,
  notes
) {
  return { id_intermediate, old_url, path, redirect_url, isDelete, notes };
}

var rows = [];
// {
//   id_intermediate: "549601195",
//   host: "www.google.com",
//   path: "/eee/wesdd/dfd",
//   redirectURL: "https://www.britishcouncilschool.com",
//   isDelete: "true",
//   notes: "Testing"
// },
// {
//   id_intermediate: "549605595",
//   host: "www.google.com",
//   path: "/eee/wesdd/dfd",
//   redirectURL: "https://www.britishcouncilschool.com",
//   isDelete: "true",
//   notes: "Testing"
// },
// {
//   id_intermediate: "522605595",
//   host: "www.google.com",
//   path: "/eee/wesdd/dfd",
//   redirectURL: "https://www.britishcouncilschool.com",
//   isDelete: "true",
//   notes: "Testing"
// },
// {
//   id_intermediate: "549444595",
//   host: "www.google.com",
//   path: "/eee/wesdd/dfd",
//   redirectURL: "https://www.britishcouncilschool.com",
//   isDelete: "true",
//   notes: "Testing"
// }];

// const rows = [
//   createData(
//     "549601195",
//     "www.google.com",
//     "/eee/wesdd/dfd",
//     "https://www.britishcouncilschool.com",
//     "true",
//     "Testing"
//   ),
//   createData(
//     "549601133",
//     "www.google.com",
//     "/eee/kew/dfd",
//     "https://www.britishcouncilschool.com",
//     "true",
//     "Testing"
//   ),
// ];

const selectedForDelete = [];
var addedForDelete = [];
var selectedCount = 0;
var confirmDel = false;
const getURL =
  "https://7a8sa4274a.execute-api.eu-west-2.amazonaws.com/default/redirectshistory";
const delURL =
  "https://7i2n9hdwmd.execute-api.eu-west-2.amazonaws.com/default/reditectsdelintermediate";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "82%",
    marginLeft: "275px",
    marginTop: "20px"
  },
  table: {
    minWidth: 950
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#e2efff"
    }
  },
  tableCell: {
    "$selected &": {
      color: "black"
    }
  },
  hover: {},
  selected: {}
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id_intermediate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [open, setOpen] = React.useState(false);
  const [rowsState, setRowsState] = React.useState([]);

  var searchResultsArr = [];
  searchResultsArr = props.searchResultsP.searchResults;
  if (searchResultsArr) {
    console.log(searchResultsArr.length);
    // rows = rows.concat(searchResultsArr);
    // setRowsState(rowsState => [...rowsState, searchResultsArr]);
  }

  useEffect(() => {
    rows = [];
    axios
      .get(getURL, {
        headers: {
          "x-api-key": `izRyaKziMF84nfLupK6R21YcMcusk9bq4IvI9YX7`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error", response);
        } else {
          // rows = rows.concat(response.data);
          // setRowsState(rowsState => [...rowsState, response.data]);

          if (searchResultsArr) {
            rows = rows.concat(searchResultsArr);
            setRowsState(rowsState => [...rowsState, searchResultsArr]);
          } else {
            rows = rows.concat(response.data);
            setRowsState(rowsState => [...rowsState, response.data]);
          }
          console.log(response.data);
        }
      })
      .catch(error => {
        console.log("error here1 ", error);
      });
  }, [searchResultsArr]);

  function toUsestate() {
    axios
      .get(getURL, {
        headers: {
          "x-api-key": `izRyaKziMF84nfLupK6R21YcMcusk9bq4IvI9YX7`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error", response);
        } else {
          if (searchResultsArr) {
            console.log("two");
            rows = rows.concat(searchResultsArr);
          } else {
            console.log("three");
            rows = rows.concat(response.data);
          }
          console.log(response.data);

        }
      })
      .catch(error => {
        console.log("error here ", error);
      });
  }

  window.beforeunload = e => {
    rows = [];
    toUsestate();
    e.returnValue = "";
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const headCells = [
    {
      id: "id_intermediate",
      numeric: false,
      disablePadding: true,
      label: "ID"
    },
    { id: "host", numeric: true, disablePadding: false, label: "Old URL" },
    { id: "path", numeric: true, disablePadding: false, label: "Path" },
    {
      id: "redirectURL",
      numeric: true,
      disablePadding: false,
      label: "Redirect URL"
    },
    { id: "isDelete", numeric: true, disablePadding: false, label: "isDelete" },
    { id: "notes", numeric: true, disablePadding: false, label: "Notes" }
  ];

  function handleDel() {
    handleClickOpen();

    var i = 0;
    var j = 0;
    var paramsDelCall = "[";

    addedForDelete.length = 0;
    for (i = 0; i < selectedForDelete.length; i++) {
      for (j = 0; j < rows.length; j++) {
        if (selectedForDelete[i].name === rows[j].id_intermediate) {
          rows.splice(j, 1);
        }
      }
    }

    for (i = 0; i < selectedForDelete.length - 1; i++) {
      paramsDelCall = paramsDelCall.concat(
        `{"id_intermediate":`,
        selectedForDelete[i].name,
        `},`
      );
    }
    paramsDelCall = paramsDelCall.concat(
      `{"id_intermediate":`,
      selectedForDelete[selectedForDelete.length - 1].name,
      `}]`
    );
    console.log(paramsDelCall);

    axios
      .post(delURL, paramsDelCall, {
        headers: {
          "x-api-key": `99pd0zetvF1KjUEUUhXXLRUVAVypHsWa5tNJYYR0`
        }
      })
      .then(response => {
        if (response.status !== 200) {
          console.log("error", response);
        } else {
          console.log("deleted successfully");
        }
      })
      .catch(error => {
        console.log("error here ", error);
      });

    setSelected("");
    selectedForDelete.length = 0;
    selectedCount = 0;
    confirmDel = false;
  }

  function handleCloseConfirm() {
    confirmDel = true;
    handleClose();
  }

  function EnhancedTableHead(props) {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
  };

  const useToolbarStyles = makeStyles(theme => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.secondary.dark
          },
    title: {
      flex: "1 1 100%"
    }
  }));

  const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
        style={{ minHeight: "0" }}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography></Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete" onClick={handleDel}>
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          console.log("")
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id_intermediate);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, isItemSelected) => {
    if (!isItemSelected) {
      selectedCount++;
    } else if (selectedCount !== 0) {
      selectedCount--;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    var s = 0;
    var remId = 0;
    var t = 0;

    selectedForDelete.push({ name, isItemSelected });

    if (selectedForDelete) {
      for (s = 0; s < selectedForDelete.length; s++) {
        if (selectedForDelete[s].isItemSelected) {
          remId = selectedForDelete[s].name;
          selectedForDelete.splice(s, 1);
          s--;
          for (t = 0; t < selectedForDelete.length; t++) {
            if (
              remId === selectedForDelete[t].name &&
              selectedForDelete[t].isItemSelected === false
            ) {
              selectedForDelete.splice(t, 1);
              t--;
            }
          }
        }
      }
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  //const isSelected = name => selected.indexOf(name) !== -1;

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selectedCount} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedCount}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id_intermediate);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id_intermediate}
                      classes={{ selected: classes.selected }}
                      className={classes.tableRow}
                    >
                      <TableCell
                        padding="checkbox"
                        className={classes.tableCell}
                      >
                       
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className={classes.tableCell}
                      >
                        {row.id_intermediate}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {row.host}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {row.path}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {decodeURIComponent(row.redirectURL)}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {row.isDelete}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {row.notes}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 33) * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

    </div>
  );
}
