import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function RedirType({newdelchk, checkChange}){
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl} style={{ color:'grey' }}>
          <FormControlLabel
            control={<Checkbox  onChange={checkChange} name="rnewdelete" size="small"/>}
            label="Is this a delete?"
          />
        <FormHelperText style={{ marginLeft: '25px' }}>Select if this is a delete</FormHelperText>
      </FormControl>
    </div>
  );
}
