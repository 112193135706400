import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import logo from '../assets/BCL.svg';
import 'fontsource-roboto';
import SideMenu from './SideMenu';
import CssBaseline from '@material-ui/core/CssBaseline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import {useAuth0} from '@auth0/auth0-react';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'black'
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: 135,
    height: 50
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const AuthNav = () => {
  const {isAuthenticated } = useAuth0();

  return (
    <div>
    {isAuthenticated ? <LogoutButton/> : console.log("") }
    </div>
  )
}

export default function ButtonAppBar() {


  const classes = useStyles();
  return (
    <div className={classes.root}>
    <CssBaseline/>
      <AppBar  position="fixed" className={classes.appBar} style={{ background: '#FFFFFF', position: 'fixed', top: 0 , left : 0, margin: 0}}>
        <Toolbar variant="dense">
        <img src={logo} alt ="BC Logo" height="30" width="75"/>
          <Typography variant="subtitle2" className={classes.title} style={{ color: '#000000',display: 'flex',  justifyContent:'center', alignItems:'center', fontWeight: 1000}}>
            Domain Redirects Application
          </Typography>
          <AuthNav/>
        </Toolbar>
      </AppBar>
      <SideMenu/>
    </div>
  );
}