import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    marginLeft: "700px",
    marginTop: "300px"
  },
  media: {
    height: 140
  }
});

export default function LoginComp() {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useAuth0();

  const loginfunc = () => {
    console.log(isAuthenticated);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ marginLeft: "85px" }}
          >
            Authentication
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ marginLeft: "59px" }}
          >
            Login to access the application
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          style={{ color: "white", marginLeft: "135px", background: "black" }}
          onClick={() => {
            loginWithRedirect();
            sessionStorage.setItem('isAuthenticated', isAuthenticated);
            console.log(sessionStorage.getItem('isAuthenticated'))
          }}
        >
          Login
        </Button>
      </CardActions>
    </Card>
  );
}
